<template>
  <alert-edit-create :organization-id="organization.id" />
</template>
<script>
import AlertEditCreate from '~/pages/shared/alerts/partials/EditCreate';

export default {

  components: {
    AlertEditCreate,
  },

  middleware: 'tenantmember',
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
};
</script>
